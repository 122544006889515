import useApiEngage from '@common/apis/engage-track/useApiEngage';
import {
  AgreementSettledEvt,
  DebtClickedEvt,
  DebtVisualizedEvt,
} from '@common/apis/engage-track/types';

import { isProd } from '@libs/utils/helpers/isProd';
import { getCookie } from '@libs/utils/helpers/getSafeCookies';

const useEngageTrack = () => {
  const { apiEngageTrackEvent } = useApiEngage();

  const sendEngageTrackEvent = (
    eventName: string,
    data: Record<string, any> = {}
  ) => {
    const payload = {
      name: eventName,
      payload: data,
    };

    if (isProd()) apiEngageTrackEvent.send(payload);

    const hasDebug = getCookie("DEBUG_ENGAGE");

    if (hasDebug) console.log('Engage integration disabled in this environment. event:', payload);
  };

  const engageEventNames = {
    DEBT_VISUALIZED: 'debt_visualized',
    DEBT_CLICKED: 'debt_clicked',
    AGREEMENT_SETTLED: 'agreement_settled',
  };

  const sendDebtVisualizedEvent = (payload: DebtVisualizedEvt) => {
    sendEngageTrackEvent(engageEventNames.DEBT_VISUALIZED, payload);
  };

  const sendDebtClickedEvent = (payload: DebtClickedEvt) => {
    sendEngageTrackEvent(engageEventNames.DEBT_CLICKED, payload);
  };
  const sendAgreementSettledEvent = (payload: AgreementSettledEvt) => {
    sendEngageTrackEvent(engageEventNames.AGREEMENT_SETTLED, payload);
  };

  return {
    sendDebtVisualizedEvent,
    sendDebtClickedEvent,
    sendAgreementSettledEvent,
    engageEventNames,
  };
};

export default useEngageTrack;
