/* import { StrictMode } from 'react'; */
import * as ReactDOM from 'react-dom/client';
import { RedlineProvider } from '@libs/redline';
import { SHProvider } from '@libs/sortinghat';

import App from './App';

window.addEventListener('vite:preloadError', () => {
  window.location.reload(); /* https://vitejs.dev/guide/build#load-error-handling */
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <RedlineProvider
    writeKey={import.meta.env.VITE_REDLINE_WRITE_KEY}
    mode={import.meta.env.VITE_ENV}
    appName={import.meta.env.VITE_REDLINE_APP_NAME}
    appVersion={import.meta.env.VITE_DEPLOY_VERSION}
    logs={false}
  >
    <SHProvider
      appName="ac"
      mode={import.meta.env.VITE_ENV}
      debug={false}
      shKey={import.meta.env.VITE_SORTING_HAT_API_KEY}
      baseURL={import.meta.env.VITE_SORTING_HAT_API_URL}
      identifier="userDocument"
      maxCallNumber={3}
    >
      <App />
    </SHProvider>
  </RedlineProvider>
);
