import { useLocation } from 'react-router-dom';

import { addCookie, getCookie } from '@libs/utils/helpers/getSafeCookies';
import { useRedline } from '@libs/redline';
import constants from '@common/constants';

const useUserAuthentication = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const implicitAccessData = searchParams.get("a");
  const engageAccessData = searchParams.get("e");
	const utmMedium = searchParams.get("utm_medium");

  const { getCorrelationId } = useRedline();

  const setRLTracksAuthCookie = () => {
    const href = window?.location?.href;
    const referrer = href?.includes('login') ? 'sign-in' : 'sign-up';
    const hasTokenAuth = getCookie(constants.legacyAuth.TOKEN);

		const engageOrigin = utmMedium && utmMedium === "sms" ? "sms-" : "email-";
		
    const origin = implicitAccessData
      ? "implicit-"
      : engageAccessData ? engageOrigin : "";

    const authenticationType =
      (hasTokenAuth && !implicitAccessData)
        ? 'cached-sign-in'
        :  `${origin}${referrer}`;

    addCookieRLTracksAuth(authenticationType);
  };

  const addCookieRLTracksAuth = (authenticationType: string) => {

    const rLTracksAuthData = {
      authenticationType,
      correlationId: getCorrelationId(constants.redline.RL_TRACK_CID),
    };

    const oneHundredTwentyDays = new Date();
    oneHundredTwentyDays.setDate(oneHundredTwentyDays.getDate() + 120);

    addCookie({
      key: constants.redline.rl_tracks_auth,
      value: JSON.stringify(rLTracksAuthData),
      encrypt: true,
      expires: oneHundredTwentyDays,
    });
  };

  return {
    setRLTracksAuthCookie,
    addCookieRLTracksAuth,
  };
};

export default useUserAuthentication;
