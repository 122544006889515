import ac from './ac.svg';
import acNew from './ac-new.svg';
import act from './act.svg';
import ampli from './webp/ampli.webp';
import anhanguera300 from './anhanguera-300.svg';
import anhangueraEad from './anhanguera-ead.svg';
import anhanguera from './anhanguera.svg';
import arc4u from './arc4u.svg';
import anhangueraead300 from './anhangueraead-300.svg';
import asp from './webp/asp.webp';
import ativossa from './webp/ativossa.webp';
import bancoDoBrasil from './webp/banco-do-brasil.webp';
import bancoNext from './webp/banco-next.webp';
import bancoPan from './banco-pan.svg';
import biva from './biva.svg';
import bradescardFmc from './bradescard-fmc.svg';
import bradescardLosango from './bradescard-losango.svg';
import bradescard from './bradescard.svg';
import bradesco from './bradesco.svg';
import brkAmbiental from './brk-ambiental.svg';
import brk from './brk.svg';
import btg from './btg.svg';
import bvFinanceira from './webp/bv-financeira.webp';
import bv from './webp/bv.webp';
import carrefourAtacadao from './carrefour-atacadao.svg';
import carrefour from './carrefour.svg';
import casasBahia from './webp/casas-bahia.webp';
import cencosud from './webp/cencosud.webp';
import cielo from './cielo.svg';
import claroFatura from './claro-fatura.svg';
import claroResidencial from './webp/claro-residencial.webp';
import claro from './claro.svg';
import colombo from './colombo.svg';
import crediativos from './crediativos.svg';
import credsystem from './credsystem.svg';
import credz from './credz.svg';
import dacasa from './dacasa.svg';
import dmLogo from './dm-logo.svg';
import edp from './webp/edp.webp';
import estacio from './estacio.svg';
import faci from './faci-wyden.svg';
import facimp from './facimp-wyden.svg';
import fmf from './webp/fmf.webp';
import fundoAtlantico from './fundo-atlantico.svg';
import havan from './havan.svg';
import hoepers from './hoepers.svg';
import ibmec from './ibmec.svg';
import ipanema from './ipanema.svg';
import itapeva from './itapeva.svg';
import itapevaadhil from './itapevaadhil.svg';
import itau from './itau.svg';
import kroton from './kroton.svg';
import liftcred from './liftcred.svg';
import lojasrenner from './lojasrenner.svg';
import luizacred from './luizacred.svg';
import mercadolivre from './webp/mercadolivre.webp';
import mercadopago from './webp/mercadopago.webp';
import mgwAtivos from './mgw-ativos.svg';
import netInterno from './webp/net-interno.webp';
import netSeo from './webp/net-seo.webp';
import net from './webp/net.webp';
import nubank from './nubank.svg';
import original from './original.svg';
import pagBank from './pag-bank.svg';
import pan from './pan.svg';
import pitagoras300 from './pitagoras-300.svg';
import pitagoras from './pitagoras.svg';
import platos from './platos.svg';
import ponto from './ponto.svg';
import portoSeguro from './porto-seguro.svg';
import recovery from './recovery.svg';
import recoveryitau from './recoveryitau.svg';
import riachueloSipf from './riachuelo-sipf.svg';
import riachuelo from './riachuelo.svg';
import saneaquaMairinque from './webp/saneaqua-mairinque.webp';
import saneaqua from './webp/saneaqua.webp';
import santander from './santander.svg';
import sky from './sky.svg';
import tribanco from './tribanco.svg';
import trigg from './trigg.svg';
import unic300 from './unic-300.svg';
import unic from './unic.svg';
import uniderp300 from './uniderp-300.svg';
import uniderp from './uniderp.svg';
import unifacid from './unifacid-wyden.svg';
import unifanor from './unifanor-wyden.svg';
import unifavip from './unifavip-wyden.svg';
import unifbv from './unifbv-wyden.svg';
import unime300 from './unime-300.svg';
import unime from './unime.svg';
import unimetrocamp from './uni-metro-camp-wyden.svg';
import uniruy from './uniruy-wyden.svg';
import unitoledo from './unitoledo-wyden.svg';
import unopar300 from './webp/unopar-300.webp';
import unopar from './webp/unopar.webp';
import uzecartoes from './uzecartoes.svg';
import viaVarejo from './via-varejo.svg';
import vivo from './vivo.svg';
import vivonext from './vivonext.svg';
import sophus from './sophus.svg';
import omni from './omni.svg';
import fama from './fama.svg';
import wyden from './wyden.svg';
import neon from './neon.svg';
import c6bank from './c6bank.svg';
// TODO: replace with new logos
import pernambucanas from './acordo-certo-pernambucanas.svg';
import oi from './acordo-certo-oi.svg';

const partnersLogos = {
  placeholder: acNew,
  ac,
  'ac-new': acNew,
  act,
  ampli,
  'anhanguera-300': anhanguera300,
  'anhanguera-ead': anhangueraEad,
  anhangueraead: anhangueraEad,
  anhanguera,
  'anhangueraead-300': anhangueraead300,
  asp,
  ativossa,
  'ativossa-nsd': ativossa,
  'banco-do-brasil': bancoDoBrasil,
  bancodobrasil: bancoDoBrasil,
  'banco-next': bancoNext,
  'banco-pan': bancoPan,
  biva,
  'bradescard-fmc': bradescardFmc,
  'bradescard-losango': bradescardLosango,
  bradescard,
  'bradescard-p1': bradescard,
  bradesco,
  'bradesco-eavm': bradesco,
  'brk-ambiental': brkAmbiental,
  brk,
  btg,
  arc4u,
  'bv-financeira': bvFinanceira,
  bv,
  c6bank,
  'carrefour-atacadao': carrefourAtacadao,
  carrefour,
  'casas-bahia': casasBahia,
  cencosud,
  cielo,
  'claro-fatura': claroFatura,
  'claro-residencial': claroResidencial,
  clarogevenue: claro,
  claro,
  colombo,
  crediativos,
  credigy: crediativos,
  credsystem,
  credz,
  dacasa,
  'dm-logo': dmLogo,
  edp,
  estacio,
  faci,
  facimp,
  fmf,
  'fundo-atlantico': fundoAtlantico,
  havan,
  hoepers,
  ibmec,
  ipanema,
  itapeva,
  itapevaadhil,
  itau,
  'itau-pf': itau,
  'itau-pj': itau,
  'itau-rdhi': itau,
  kroton,
  fama,
  liftcred,
  lojasrenner,
  luizacred,
  mercadolivre,
  mercadopago,
  'mgw-ativos': mgwAtivos,
  'net-interno': netInterno,
  'net-seo': netSeo,
  net,
  nubank,
  oi,
  omni,
  original,
  'pag-bank': pagBank,
  pagbank: pagBank,
  pan,
  'pitagoras-300': pitagoras300,
  pitagoras,
  platos,
  ponto,
  'porto-seguro': portoSeguro,
  recovery,
  recoveryitau,
  'riachuelo-sipf': riachueloSipf,
  'riachuelo-v2': riachuelo,
  riachuelo,
  'saneaqua-mairinque': saneaquaMairinque,
  saneaqua,
  santander,
  sky,
  tribanco,
  trigg,
  'unic-300': unic300,
  unic,
  'uniderp-300': uniderp300,
  uniderp,
  unifacid,
  unifanor,
  unifavip,
  unifbv,
  'unime-300': unime300,
  unime,
  unimetrocamp,
  uniruy,
  unitoledo,
  'unopar-300': unopar300,
  unopar,
  uzecartoes,
  'via-varejo': viaVarejo,
  vivo,
  vivocyber: vivo,
  vivonext,
  sophus,
  pernambucanas,
  'pernambucanas-ws': pernambucanas,
  'pernambucanas-offline': pernambucanas,
  wyden,
  neon,
};

export default partnersLogos;
