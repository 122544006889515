import { ReactNode, createContext, useEffect, useRef } from 'react';
import { createID, createRedline } from 'redline-client-sdk';

import { RLContext } from '../types';
import { autoEvents } from '../events/auto';
import { ecommerceEvents } from '../events/ecommerce';
import { experienceEvents } from '../events/experience';
import { sortingHatEvents } from '../events/sortingHat';
import { userTrackingEvents } from '../events/userTracking';
import { userAuthenticationEvents } from '../events/userAuthentication';
import { dogxEvents } from '../events/dogx';
import { agreementsEvents } from '../events/agreements';
import { debtsEvents } from '../events/debts';
import { mobileEvents } from '../events/mobile';
import { addCookie, getCookie } from '@libs/utils/helpers/getSafeCookies';

import { usePixel } from '../hooks/usePixel';

const RedlineContext = createContext<RLContext>({} as RLContext);
interface IRedlineProvider {
  children: ReactNode;
  writeKey: string;
  mode: 'dev' | 'prod';
  logs: boolean;
  appName?: string;
  appVersion?: string;
}

const RedlineProvider: React.FC<IRedlineProvider> = ({
  children,
  writeKey,
  mode,
  logs,
  appName,
  appVersion,
}) => {
  const redlineInstance = useRef(
    createRedline({
      writeKey,
      mode,
      logs,
      appName,
      appVersion,
      /* nativeEvents: true, */
    })
  );
  const { startPixel } = usePixel();

  useEffect(() => {
    redlineInstance.current.start();

    if (appName?.includes('cp')) {
      startPixel();
    }
  }, []);

  const events = {
    track: {
      auto: autoEvents(redlineInstance.current),
      ecommerce: ecommerceEvents(redlineInstance.current),
      experience: experienceEvents(redlineInstance.current),
      sortingHat: sortingHatEvents(redlineInstance.current),
      userTracking: userTrackingEvents(redlineInstance.current),
      userAuthentication: userAuthenticationEvents(
        redlineInstance.current,
        appName
      ),
      agreements: agreementsEvents(redlineInstance.current),
      debts: debtsEvents(redlineInstance.current),
      dogx: dogxEvents(redlineInstance.current),
      mobile: mobileEvents(redlineInstance.current),
    },
  };

  function createCorrelationId(key: string) {
    const correlationId = createID();
    const thirtyMinutes = new Date();
    const getCurrentId = getCookie(key);

    if (!getCurrentId) {
      thirtyMinutes.setTime(thirtyMinutes.getTime() + 30 * 60 * 1000);

      addCookie({
        key,
        value: correlationId,
        expires: thirtyMinutes,
      });
    }
  }

  function getCorrelationId(key: string) {
    return getCookie(key);
  }

  function endSession() {
    /* redlineInstance.current.endSession(); */
  }

  return (
    <RedlineContext.Provider
      value={{ ...events, createCorrelationId, getCorrelationId, endSession }}
    >
      {children}
    </RedlineContext.Provider>
  );
};

export { RedlineProvider, RedlineContext };
