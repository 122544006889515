import { Redline } from 'redline-client-sdk';
import * as TPayload from './types/debts-payload';
import * as TProps from './types/debts-props';

import { controlEventSending, removeCtrlCookieOnEventError } from './rl-send-events-by-session';
import RLPayloadCreator from './rl-debts-payload.creator';

const CTRL_DEBTS_COOKIE_KEY = "rl_ctrl_debts_react";

export const debtsEvents = (rl: Redline) => ({
  /**
   * @description Deve disparar uma vez para cada parceiro onde for localizada dívida (condicionada à resposta da sorting-debts).
   *
   * @param {PartnerSystem} props.partnerSystem - Objeto que contém as informações do sistema do parceiro.
   *
   * @example
   * track.debts.DebtLocated({
   *   partnerSystem: partnerSystem,
   * });
   */
  debtLocated(props: TProps.DebtLocatedProps, cbWhenRun?: () => void) {
    const { partnerSystem } = props;

    const event = 'debts.DebtLocated.v1';
    const idEvent = `Located|${partnerSystem.id}|${partnerSystem.partnerIdentifier}`;

    const data: TPayload.DebtLocated  = {
      correlationId: RLPayloadCreator.createCorrelationId(partnerSystem),
      partnerContext: RLPayloadCreator.partnerContext(partnerSystem),
    };

    controlEventSending(CTRL_DEBTS_COOKIE_KEY, idEvent, () => {
      rl.track(event, { ...data }).catch((err: Error) => {
        removeCtrlCookieOnEventError(CTRL_DEBTS_COOKIE_KEY, idEvent, err)
      });
      if (cbWhenRun) cbWhenRun();
    });
  },

  /**
   * @description Deve disparar uma vez para cada dívida simulada (condicionada à resposta da sorting-debts).
   *
   * @param {paymentOption} props.paymentOption - Opção de pagamento da dívida.
   * @param {Debt} props.debt - Objeto da dívida atual.
   *
   * @example
   * track.debts.debtSimulated({
   *   paymentOption: paymentOption,
   *   debt: debt,
   * });
   */
  debtSimulated(props: TProps.DebtSimulatedProps) {
    const event = 'debts.DebtSimulated.v1';
    const idEvent = `Simulated|${props.debt.id}`;

    const data: TPayload.DebtSimulated = {
      ...RLPayloadCreator.EventsDebtsContext(props),
    };

    controlEventSending(CTRL_DEBTS_COOKIE_KEY, idEvent, () => {
      rl.track(event, { ...data }).catch((err: Error) => {
        removeCtrlCookieOnEventError(CTRL_DEBTS_COOKIE_KEY, idEvent, err)
      });
    });
  },

  /**
   * @description Deve disparar uma vez para cada dívida exibida no carrossel, deve estar no “campo de visão do usuário.
   *
   * @param {string} props.position - Posição da dívida no carrossel (index).
   * @param {paymentOption} props.paymentOption - Opção de pagamento da dívida.
   * @param {Debt} props.debt - Objeto da dívida atual.
   * @param {boolean | undefined} props.hasCountdown - Indica se a dívida possui o countdown atrelado.
   *
   * @example
   * track.debts.debtViewed({
   *   position: parseInt(index) + 1,
   *   paymentOption: debt.paymentOptions[0],
   *   debt: debt,
   *  ...(props.hasCountdown &&{ hasCountdown: props.hasCountdown}),
   * });
   */
  debtViewed(props: TProps.DebtViewedProps, cbWhenRun?: () => void) {
    const event = 'debts.DebtViewed.v1';
    const idEvent = `Viewed|${props.debt.id}`;

    const data: TPayload.DebtViewed = {
     ...(props.hasCountdown &&{ hasCountdown: props.hasCountdown}),
      position: props.position,
      statusSortingDebt: props.statusSortingDebt,
      ...RLPayloadCreator.EventsDebtsContext(props),
    };

    controlEventSending(CTRL_DEBTS_COOKIE_KEY, idEvent, () => {
      rl.track(event, { ...data }).catch((err: Error) => {
        removeCtrlCookieOnEventError(CTRL_DEBTS_COOKIE_KEY, idEvent, err)
      });
      if(cbWhenRun) cbWhenRun()
    });
  },

  /**
   * @description Deve disparar quando o usuário clica no botão “Negociar Agora” de qualquer dívida no carrossel.
   *
   * @param {string} props.position - Posição da dívida no carrossel (index).
   * @param {paymentOption} props.paymentOption - Opção de pagamento da dívida.
   * @param {Debt} props.debt - Objeto da dívida atual.
   *
   * @example
   * track.debts.debtViewed({
   *   position: parseInt(index) + 1,
   *   paymentOption: debt.paymentOptions[0],
   *   debt: debt,
   * });
   */
  debtClicked(props: TProps.DebtClickedProps) {
    const data: TPayload.DebtClicked = {
      position: props.position,
      ...RLPayloadCreator.EventsDebtsContext(props),
    };

    rl.track('debts.DebtClicked.v1', { ...data });
  },

  /**
   * @description Dispara quando um boleto é gerado com sucesso através da API.
   *
   * @param {string} props.barcode - Código de barras do boleto gerado, responseBillet?.linhaDigitavel.
   * @param {paymentOption} props.paymentOption - Opção de pagamento selecionada.
   * @param {Debt} props.debt - Objeto da dívida atual.
   * @param {any} props.agreement - Objeto do acordo fechado.
   *
   * @example
   * track.debts.billetGenerated({
   *   barcode: responseBillet?.linhaDigitavel,
   *   paymentOption: paymentOption,
   *   debt: debt,
   *   agreement: agreement,
   * });
   */
  billetGenerated(props: TProps.BilletGeneratedProps) {
    const data: TPayload.BilletGenerated = {
      barcode: props?.barcode || '',
      ...RLPayloadCreator.EventsDebtsContext(props),
    };

    rl.track('debts.BilletGenerated.v1', { ...data });
  },

    /**
     * @description Dispara em caso de erro ao tentar gerar o boleto através da chamada à API /billet.
     *
     * @param {string} props.errorDetails - Detalhes adicionais sobre o erro ocorrido.
     * @param {string} props.errorMessage - Mensagem descritiva do erro.
     * @param {string} props.errorType - Tipo de erro ocorrido.
     * @param {paymentOption} props.paymentOption - Opção de pagamento selecionada.
     * @param {Debt} props.debt - Objeto da dívida atual.
     * @param {any} props.agreement - Objeto do acordo fechado.
     *
     * @example
     * track.debts.billetGenerationErrored({
     *		errorDetails: err.error?.fields?.[0]?.field_name,
     *		errorMessage: err.error?.message,
     *		errorType: err.error?.error_slug,
     *  	paymentOption: this.paymentOption,
     *  	debt: this.debt,
     *  	agreement: this.agreement,
     * });
     */
  billetGenerationErrored(props: TProps.BilletGenerationErroredProps) {
    const { errorDetails, errorMessage, errorType } = props;

    const data: TPayload.BilletGenerationErrored = {
      errorDetails,
      errorMessage,
      errorType,
      ...RLPayloadCreator.EventsDebtsContext(props),
    };

    rl.track("debts.BilletGenerationErrored.v1", { ...data });
  },

  /**
   * @description Dispara quando um código PIX é gerado com sucesso através da API.
   *
   * @param {string} props.pixCode - Código PIX gerado.
   * @param {paymentOption} props.paymentOption - Opção de pagamento selecionada.
   * @param {Debt} props.debt - Objeto da dívida atual.
   * @param {any} props.agreement - Objeto do acordo fechado.
   * @param {boolean} props.isExternalPix - Indica se o pix foi gerado pelo parceiro ou internamente.
   *
   * @example
   * track.debts.pixGenerated({
   *   pixCode: responsePix?.data?.pix_code,
   *   paymentOption: paymentOption,
   *   debt: debt,
   *   agreement: agreement,
   *   isExternalPix: isExternalPix
   * });
   */
  pixGenerated(props: TProps.PixGeneratedProps) {
    const data: TPayload.PixGenerated = {
      pixCode: props.pixCode || '',
      isExternalPix: props?.isExternalPix || false,
      ...RLPayloadCreator.EventsDebtsContext(props),
    };

    rl.track('debts.PixGenerated.v1', { ...data });
  },

  /**
   * @description Dispara em caso de erro ao tentar gerar o PIX através da chamada à API para /qrcode.
   *
   * @param {string} props.errorDetails - Detalhes adicionais sobre o erro ocorrido.
   * @param {string} props.errorMessage - Mensagem descritiva do erro.
   * @param {string} props.errorType - Tipo de erro ocorrido.
   * @param {paymentOption} props.paymentOption - Opção de pagamento selecionada.
   * @param {Debt} props.debt - Objeto da dívida atual.
   * @param {any} props.agreement - Objeto do acordo fechado.
   *
   * @example
   * track.debts.pixGenerationErrored({
   *		errorDetails: err.error?.fields?.[0]?.field_name,
   *		errorMessage: err.error?.message,
   *		errorType: err.error?.error_slug,
   *  	paymentOption: paymentOption,
   *  	debt: debt,
   *  	agreement: agreement,
   * });
   */
  pixGenerationErrored(props: TProps.PixGenerationErroredProps) {
    const { errorDetails, errorMessage, errorType } = props;

    const data: TPayload.PixGenerationErrored = {
      errorDetails,
      errorMessage,
      errorType,
      ...RLPayloadCreator.EventsDebtsContext(props),
    };

    rl.track('debts.PixGenerationErrored.v1', { ...data });
  },

  /**
   * @description Dispara quando o status do PIX evolui para pago através da chamada à API de status do PIX.
   *
   * @param {string} props.pixCode - Código PIX gerado.
   * @param {paymentOption} props.paymentOption - Opção de pagamento selecionada.
   * @param {Debt} props.debt - Objeto da dívida atual.
   * @param {any} props.agreement - Objeto do acordo fechado.
   *
   * @example
   * track.debts.pixPayed({
   *   pixCode: responsePix?.data?.pix_code,
   *   paymentOption: paymentOption,
   *   debt: debt,
   *   agreement: agreement,
   * });
   */
  pixPayed(props: TProps.PixPayedProps) {
    const data: TPayload.PixPayed = {
      pixCode: props.pixCode || '',
      ...RLPayloadCreator.EventsDebtsContext(props),
    };

    rl.track('debts.PixPayed.v1', { ...data });
  },

  /**
   * @description Rastreia o evento de quando o usuário chega na página /confirmacao.
   *
   * @param {paymentOption} props.paymentOption - Opção de pagamento selecionada.
   * @param {Debt} props.debt - Objeto da dívida atual.
   *
   * @example
   * track.debts.agreementStarted({
   *   paymentOption: paymentOption,
   *   debt: debt,
   * });
   */
  agreementStarted(props: TProps.AgreementStartedProps) {
    const data = {
      ...RLPayloadCreator.EventsDebtsContext(props),
    };

    rl.track('debts.AgreementStarted.v1', { ...data });
  },

  /**
   * @description Rastreia o evento de clique do usuário no botão "Concluir acordo" na página /confirmacao.
   *
   * @param {paymentOption} props.paymentOption - Opção de pagamento selecionada.
   * @param {Debt} props.debt - Objeto da dívida atual.
   * @param {any} props.agreement - Objeto do acordo fechado.
   *
   * @example
   * track.debts.agreementSubmitted({
   *   paymentOption: paymentOption,
   *   debt: debt,
   *   agreement: {},
   * });
   */
  agreementSubmitted(props: TProps.AgreementSubmittedProps) {
    rl.track('debts.AgreementSubmitted.v1', {
      ...RLPayloadCreator.EventsDebtsContext(props),
    });
  },

  /**
   * @description Rastreia o evento da opção de pagamento selecionada.
   *
   * @param {boolean} props.autofilled - Valor que se autopreenchido deve ser verdadeiro, caso seja um valor selecionado pelo usuário, deve ser falso.
   * @param {string} props.fieldId - Identificador único do campo.
   * @param {string} props.fieldLabel - Label do campo exibida para o usuário.
   * @param {string} props.fieldName - Nome do campo.
   * @param {string} props.fieldValue - Identificador do valor do campo selecionado.
   *
   * @example
   * track.debts.fieldSelected({
      userInputField: {
        autofilled: isAutofilled,
        fieldId: "example_id",
        fieldLabel: "label_example",
        fieldName: "field_name_example",
        fieldValue: 123456,
      },
   * });
   */
  fieldSelected(props: TProps.FieldSelectedProps) {
    const { userInputField } = props;

    rl.track('userTracking.FieldSelected.v1', {
      userInputField,
    });
  },

  /**
   * @description Rastreia o evento de sucesso no retorno da /fechar, sinalizando que o acordo foi fechado com êxito.
   *
   * @param {paymentOption} props.paymentOption - Opção de pagamento selecionada.
   * @param {Debt} props.debt - Objeto da dívida atual.
   * @param {any} props.agreement - Objeto do acordo fechado.
   *
   * @example
   * track.debts.agreementOutcomeReceived({
   *  	paymentOption: paymentOption,
   *  	debt: debt,
   *  	agreement: agreement,
   * });
   */
  agreementOutcomeReceived(props: TProps.AgreementOutcomeReceivedProps) {
    rl.track('debts.AgreementOutcomeReceived.v1', {
      ...RLPayloadCreator.EventsDebtsContext(props),
    });
  },

  /**
   * @description Rastreia o evento de quando ocorre um erro na chamada para a rota /fechar.
   *
   * @param {paymentOption} props.paymentOption - Opção de pagamento selecionada.
   * @param {Debt} props.debt - Objeto da dívida atual.
   * @param {any} props.agreement - Objeto do acordo fechado.
   * @param {string} props.errorDetails - Detalhes adicionais sobre o erro ocorrido.
   * @param {string} props.errorMessage - Mensagem descritiva do erro.
   * @param {string} props.errorType - Tipo de erro ocorrido.
   *
   *  @example
   * track.debts.agreementErrored({
   *		errorDetails: err.error?.fields?.[0]?.field_name,
   *		errorMessage: err.error?.message,
   *		errorType: err.error?.error_slug,
   *  	paymentOption: paymentOption,
   *  	debt: debt,
   *  	agreement: {},
   * });
   *
   */
  agreementErrored(props: TProps.AgreementErroredProps) {
    const { errorDetails, errorMessage, errorType } = props;

    const data = {
      errorDetails,
      errorMessage,
      errorType,
      ...RLPayloadCreator.EventsDebtsContext(props),
    };
    return rl.track('debts.AgreementErrored.v1', { ...data });
  },
});
